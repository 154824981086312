import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ResponseStyle.css";
import SuccessIcon from "./../../assets/Purchase_Success.png";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "../invoice.js";

const generateInvoiceNumber = () => {
  const now = new Date();
  const year = now.getFullYear();
  const randomNum = Math.floor(1000 + Math.random() * 9000);
  return `INV-${year}-${randomNum}`;
};

const getCurrentDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  return `${day}-${month}-${year}`;
};

const Response = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Check if razorpay_payment_id is present in localStorage
  useEffect(() => {
    const paymentId = localStorage.getItem("razorpay_payment_id");
    if (!paymentId) {
      navigate("/");
    }
  }, [navigate]);

  const {
    name,
    email,
    address,
    amount = 14514,
    mobile_number,
    gst = 3186,
    totalAmount,
  } = location.state || {};
  const invoiceNo = generateInvoiceNumber();
  const invoiceDate = getCurrentDate();
  const calculatedTotalAmount = totalAmount || amount + gst;

  const invoiceData = {
    invoiceNo: invoiceNo,
    date: invoiceDate,
    billTo: {
      name: name || "Customer Name",
      address: address || "Customer Address",
      email: email || "Customer Email",
      mobile: mobile_number || "1234567891",
    },
    from: {
      name: "XUV TECHNOLOGY",
      address: "F 96, The Zone Chandavarkar Road, Borivali West Mumbai 400092",
      email: "sylfaen.software@gmail.com",
      mobile: "9323572423",
    },
    items: [
      {
        name: "Software License",
        quantity: 1,
        price: amount || 15000,
        gst: gst || 2700,
        gst: gst,
        totalAmount: calculatedTotalAmount,
      },
    ],
    total: calculatedTotalAmount,
    paymentMethod: "Online",
    note: "Thank you for your purchase!",
  };

  // Handles logout with a delay of 30 seconds
  const handleDownloadAndLogout = () => {
    window.location.href =
      "https://sylfaen-font-installer.s3.ap-south-1.amazonaws.com/Sylfaen.exe.zip";
    setTimeout(() => {
      localStorage.removeItem("razorpay_payment_id");
      navigate("/");
    }, 15000);
  };

  return (
    <div className="response_container">
      <div className="response_content">
        <img src={SuccessIcon} alt="Success" />
        <h1>Registration Successful!</h1>
        <p>Thank you for your purchase, {name || "John Doe"}!</p>
        <div className="download">
          {/* Download Receipt Button */}
          <PDFDownloadLink
            document={<InvoiceDocument data={invoiceData} />}
            fileName="invoice.pdf"
            className="download_btn"
          >
            {({ loading }) =>
              loading ? "Generating receipt..." : "Download Receipt"
            }
          </PDFDownloadLink>
          <button onClick={handleDownloadAndLogout} className="download_btn">
            Download Software
          </button>
        </div>
      </div>
    </div>
  );
};

export default Response;
